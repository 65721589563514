import { CircularProgress } from '@mui/material';
import React, { FC } from 'react';

import { AbstractEmptyInterface } from '../../../types';

interface loadingProps { 
    className?: string;
};

const Loading: FC<loadingProps> = (props) => {
    return (
        <CircularProgress variant='indeterminate' color='primary' className={props.className} ></CircularProgress>
    )
};

export default Loading;
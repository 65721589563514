import React, { Suspense, useRef } from 'react';
import './App.css';
import { ThemeProvider, CssBaseline } from '@mui/material';
import GlobalTheme from './theme/globalTheme';
import { Provider as StoreProvider } from 'mobx-react';
import Router from './router';
import { BrowserRouter } from 'react-router-dom';
import Loading from './components/common/loading/Loading'
import stores from './stores'
import Snackar from './components/common/SnackBar';
import authServices from './services/AuthServices'

function App() {

  const willMount = useRef(true);

  if (willMount.current) {
    authServices.isAuthenticated();
    willMount.current = false;
  }
  
  return (
    <ThemeProvider theme={GlobalTheme}>
      <StoreProvider {...stores}>
        <BrowserRouter>
          <Suspense fallback={<Loading />}>
              <Snackar/>        
              <Router/>  
            <CssBaseline />
          </Suspense> 
        </BrowserRouter>
      </StoreProvider>
    </ThemeProvider>
  );
}

export default App;

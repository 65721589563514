import React, { FC } from 'react'
import { AppBar, Toolbar } from '@mui/material';
import ShoppingCart from '@mui/icons-material/ShoppingCart';

import mcilogo from '../../../assets/images/mcilogo.png';
import mci from '../../../assets/images/mci.png';



import useStyles from './style';
import { useNavigate } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { AbstractEmptyInterface } from '../../../types';
import { AppStoreInterface } from '../../../stores/AppStore';


interface homeProps extends AbstractEmptyInterface {
  appStore: AppStoreInterface;
}

const  MainNav:FC<AbstractEmptyInterface> = (props) => {

  const classes = useStyles();
  const navigate = useNavigate();

  const { appStore } = props as homeProps;

  const handleGoHome = () => {
    navigate('/home');
  }

  const handleViewCart = () => {
    navigate('/cart');
  }

  return (
    <div className={classes.container}>
      <div className={classes.shoppingCart} onClick={handleViewCart}> 
        <ShoppingCart />

        <span className={classes.quantity}>
          <span className={classes.quantityValue}>
          { appStore.itemsInCart}
          </span>         
        </span>         
      </div>
      <img src={mcilogo} alt="globe" className={classes.globe} onClick={handleGoHome}/>
      <div className={classes.slogan}>Une expertise du droit malgache alliee a l'experience internationale</div>
    </div>
  )
}


export default inject("appStore")(observer(MainNav));
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme)=> ({
 
  mci : {
    display: 'flex',
    justifyContent:'center',
    backgroundColor: '#DADADA',
  },

  app: {
    display: 'flex',
    flexDirection: 'row',
    width: '90vw',   
    
    '& .MuiGrid-root' : {
      backgroundColor: 'white',
    },

    '& .MuiGrid-item' : {
      alignItem:'center',
    },
  },

  mainNav : {  
    [theme.breakpoints.down('sm')]: {
      position: "fixed",
      top:0,
      left:0,
      width : '100%',
      height: '10vh',
      zIndex: 3,
    },

    [theme.breakpoints.up('sm')]: {
      position: "fixed",
      top:0,
      left:0,
      width : '100%',
      height: '12vh',
      zIndex: 3,
    },
  },

  content: { 
    width: '100%',
    height: '85vh',
    marginTop: '17vh',
    zIndex: 1,
    backgroundColor: '#ffffcd',
  },

  
}));

export default useStyles;

import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme)=> ({
  container: {
    minwidth: '80vw',
    height: '100%',  
  },

  globe: {
    [theme.breakpoints.down('sm')]: {
      // width:'80%',
      height:'60%',
    },
    
    [theme.breakpoints.up('sm')]: {
      // width:'60%',
      height:'70%',
    },
    
  },

  slogan: {
    [theme.breakpoints.down('sm')]: {
      height:'30%',
      backgroundColor : '#022545',
      color: 'white',
      alignContent:'center',
      textAlign: 'center',
    },
    
    [theme.breakpoints.up('sm')]: {
      height:'30%',
      backgroundColor : '#022545',
      color: 'white',
      alignContent:'center',
      textAlign: 'center',
    },
    
  },

  shoppingCart: {
    float:'right',
    cursor: 'pointer',
    marginRight: '30px',
    marginTop: '30px',
  },

  quantity: {
    display: 'inline-block',
    backgroundColor : 'red',
    borderRadius:'50%',
    width:'30px',
    height:'30px',
    color : 'white',
  },
  quantityValue: {
    marginLeft: '8px'
  }

}));

export default useStyles;

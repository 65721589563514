import { Container } from '@mui/material';
import Grid from '@mui/material/Grid';
import React,{ FC } from 'react';
import MainNav from '../mainNav/MainNav';

import { AbstractEmptyInterface } from '../../../types';

import useStyles from './style';
import LeftNav from '../leftNav';
import RightNav from '../rightNav';

interface LayoutProps extends AbstractEmptyInterface {
    children:any;
};

const Layout: FC<AbstractEmptyInterface> = ( props) => {

    const {children} = props as LayoutProps;

    const classes = useStyles();

    return (
        <div className={classes.mci}>
            <Grid container={true} className={classes.app}>
                <Grid item={true} xs={12} className={classes.mainNav}>
                    <MainNav></MainNav>
                </Grid>
                <Grid item={true} container={true} xs={12} className={classes.content}>                                   
                    {children} 
                </Grid>
            </Grid>  
        </div>
    )
};

export default Layout;
import axios from 'axios';
import { action, makeObservable, observable, computed } from 'mobx';
import config from '../config';
import { IReview } from '../models/reviewInterface';
import { IRubrique } from '../models/rubriqueInterface';
import { IThematic } from '../models/thematicInterface';

import { SnackBarSeverity } from '../types/'

export interface CartItem {
  review: IReview;
  unit: number;
}

export interface AppStoreInterface {
  
  isLoading : boolean;
  itemsInCart: number;
  review: IReview | null;
  selectedReview?: IReview;

  cart: CartItem[];
  
  selectedThematic?: number;
  selectedRubrique?: number;
  allThematics: IThematic[];
  allRubriques: IRubrique[];
  allReviews : IReview[];

  snackBarState: {
    open: boolean;
    severity: SnackBarSeverity;
    duration: number;
    message: string;
  };

  updateSnackBar: (
    open: boolean,
    message?: string,
    severity?: SnackBarSeverity,
    duration?: number
  ) => void;

  succesSnackBar: (
    open: boolean,
    message?: string,
    severity?: SnackBarSeverity,
    duration?: number
  ) => void;

  getAllThematics: () => Promise<any>;
  getAllRubriques: () => Promise<any>;
  getAllReviews: () => Promise<any>;
  setReview: (review: IReview | null) => void;
  setSelectedReview: (review: IReview) => void;
  setSelectedReviewById: (id?: string) => void;
  getReviewById: (id:number) => Promise<any>;
  addToCart: (review: IReview) => void;
  updateCart: (review: IReview, quantity: number) => void;
  removeFromCart: (reviewId?: string) => void;

  filterBy: (value: number, type: 'thematique' | 'rubrique') => Promise<any>;
}

class AppStore implements AppStoreInterface {
  
  @observable isLoading = false;

  @observable cart: CartItem[] = [];

  @observable review:IReview | null = null ;

  @observable selectedThematic: number | undefined;
  @observable selectedRubrique: number | undefined;
  @observable selectedReview: IReview | undefined;

  @observable allThematics: IThematic[] = [];
  @observable allRubriques: IRubrique[] = [];
  @observable allReviews: IReview[] = [];

  @observable snackBarState = {
    open: false,
    severity: 'error' as SnackBarSeverity,
    duration: 6000,
    message: 'An arror happened',
  };

  @computed get itemsInCart(): number {
    return this.cart.reduce((sum, curr) => sum + curr.unit, 0);
  } 

  constructor() {
    makeObservable(this);
  }

  @action setReview = (rev: IReview | null) => {
    this.review = rev;
  };

  @action setSelectedReview =  (review: IReview) => {
    this.selectedReview = review;
  }

  @action setSelectedReviewById =  (id?: string) => {
    console.log('allreview = ', this.allReviews);
    this.selectedReview = this.allReviews.find((rev) => rev.rev_id == id);
    console.log('this selected = ', this.selectedReview);
    
  }

  @action getReviewById = async (id:number) => {
    try {
      this.isLoading = true;
      const res: any = await axios.post(`${config.servers.apiUrl}review/${id}`);
      
      this.setReview = res.data.review;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
    } finally {
      this.isLoading = false;
    }
  };


  @action getAllThematics = async () => {
    try {
      this.isLoading = true;
      const res = await axios.get(`${config.servers.apiUrl}thematic/getAllThematic`);   
      this.allThematics = res.data.allThematic.rows;
    } catch (error) {
      this.isLoading = false;
    } 
  };

  @action getAllRubriques = async () => {
    try {
      this.isLoading = true;
      const res = await axios.get(`${config.servers.apiUrl}rubrique/getAllRubrique`);
      this.allRubriques = res.data.allRubrique.rows;
    } catch (error) {
      this.isLoading = false;
    } 
  };

  @action getAllReviews = async () => {
    try {
      this.isLoading = true;
      const res = await axios.get(`${config.servers.apiUrl}review/getAllReview`);
      this.allReviews = res.data.allReview.rows;      
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
    } finally {
      this.isLoading = false;
    }
  };

  @action filterBy = async (value: number, type: 'thematique' | 'rubrique') => {
    try {
      if (type === 'thematique') {
        this.selectedThematic = value;
      }
      if (type === 'rubrique') {
        this.selectedRubrique = value;
      }
      const res = await axios.post(`${config.servers.apiUrl}review/filterReview`, 
        {
          idThematic: this.selectedThematic, 
          idRubrique: this.selectedRubrique
        }
      );
      this.allReviews = res.data.allReview.rows;      
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
    } finally {
      this.isLoading = false;
    }
  };

  @action updateSnackBar = (
    open: boolean,
    message = "Une erreur s'est produite",
    severity: SnackBarSeverity = 'error',
    duration = 4000
  ) => {
    if (!open) {
      this.snackBarState = {
        ...this.snackBarState,
        open,
      };
      return;
    }
    this.snackBarState = {
      open,
      message,
      severity,
      duration,
    };
  };

  @action succesSnackBar = (
    open: boolean,
    message = 'Succès',
    severity: SnackBarSeverity = 'success',
    duration = 4000
  ) => {
    this.snackBarState = {
      open,
      message,
      severity,
      duration,
    };
  };

  @action addToCart = (review: IReview) => {
    this.cart.push({ review, unit: 1 });
  }

  @action updateCart = (review: IReview, quantity: number) => {
    this.cart = this.cart.map((item) => {
      if(review.rev_id === item.review.rev_id) {
        return {
          review,
          unit: quantity
        }
      }
      return item;
    })
  }

  @action removeFromCart = (reviewId?: string) => {
    this.cart = [...this.cart].filter((item) => item.review.rev_id !== reviewId)
  }

}

export default new AppStore();

import axios from 'axios';
import { action, makeObservable, observable } from 'mobx';
import config from '../config';
import { IUser } from '../models/userInterface';
import authServices from '../services/AuthServices';
import appStore from './AppStore';


export interface AuthStoreInterface {
  isLogginIn: boolean;
  user: IUser | null;
  login: (username: string, password: string) => Promise<void>;
  setUser: (infoUser: {token: string, user: IUser} | null) => void;
  loginFacebook:() =>  Promise<void>;
}

class AuthStore implements AuthStoreInterface {
  @observable isLogginIn = false;

  @observable user: IUser | null = null;

  constructor() {
    makeObservable(this);
  }

  @action setUser = (infoUser: {token: string, user: IUser} | null) => {
    if (!infoUser) {
      this.user = null;
      authServices.signOut();
      return;
    }
    const { token, user } = infoUser;
    authServices.setAccessToken(token);
    this.user = user;
  };

  @action login = async (login: string, password: string) => {
    this.isLogginIn = true;
    try {
      const resp = await axios.post(`${config.servers.apiUrl}auth/login`, {
        email: login,
        password,
      });
      this.setUser(resp.data);
      this.isLogginIn = false;
    } catch (err: any) {
      this.isLogginIn = false;

      console.log(err)

      console.log('eto:');
      
      
      appStore.updateSnackBar(true,'user or password incorrect');
    }
  };

  @action loginFacebook = async () => {
    this.isLogginIn = true;
    try {
      const resp = await axios.get(`${config.servers.apiUrl}auth/loginFacebook`);
      this.setUser(resp.data);
      this.isLogginIn = false;
    } catch (err: any) {
      this.isLogginIn = false;

      console.log(err)

      console.log('eto:');
      
      
      appStore.updateSnackBar(true,'user or password incorrect');
    }
  };
}

export default new AuthStore();

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    // '& > * + *': {
    //   marginTop: '10px',
    // },
  },
}));

export default useStyles;

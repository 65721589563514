import axios from 'axios';
import React,{ lazy} from 'react';
import {
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import ProtectedRoute from '../components/common/protectedRoute';
import AuthServices from '../services/AuthServices';
import { allUsers } from '../types';


const Login = lazy(() => import("../components/user/login/Login")); 
const Signup = lazy(() => import("../components/user/signup/Signup")); 
const Home = lazy(() => import("../components/home/Home")); 
const Subscribe = lazy(() => import("../components/subscribe")); 
const Review = lazy(() => import("../components/review")); 
const ReviewDetail = lazy(() => import("../components/review/reviewDetail")); 
const Cart = lazy(() => import("../components/cart")); 

let signOutTime: any = 0;

const signOut = () => {
    AuthServices.setAccessToken("");
    AuthServices.signOut();
};

const startTimer = () => {
  signOutTime = setTimeout(signOut, 1800000);
};

const Router = () => {

  axios.interceptors.response.use((resp) => {
    const { token } = resp.headers;
    if (token !== "" && token !== undefined) {
      if (signOutTime === 0 || signOutTime === undefined) {
        startTimer();
      } else {
        clearTimeout(signOutTime);
        startTimer();
      }
      AuthServices.setAccessToken(token);
    }
    return resp;
  });
  
  return (
    <Routes>
      <Route path="/" element={<Navigate replace  to="/home" />}/>
      <Route path="/login" element={<Login/>}/>
      <Route path="/signup" element={<Signup/>}/>     
      <Route element={<ProtectedRoute access={allUsers}/>}>
        <Route path="/home" element={<Home/>}/>
      </Route>
      <Route element={<ProtectedRoute access={allUsers}/>}>
        <Route path="/subscribe" element={<Subscribe/>}/>
      </Route>
      <Route element={<ProtectedRoute access={allUsers}/>}>
        <Route path="/review/:year" element={<Review/>}/>
      </Route>
      <Route element={<ProtectedRoute access={allUsers}/>}>
        <Route path="/review/detail/:id" element={<ReviewDetail/>}/>
      </Route>
      <Route element={<ProtectedRoute access={allUsers}/>}>
        <Route path="/cart" element={<Cart/>}/>
      </Route>
    </Routes>
  )
}

export default Router;